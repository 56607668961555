import request from "@/utils/request";

export function getListPermission(data) {
  return request({
    method: "post",
    url: "leaves/list",
    data: data
  });
}

export function getListMaster(data) {
  return request({
    method: "post",
    url: "leaves/leave",
    data: data
  });
}

export function addPermission(data) {
  return request({
    method: "post",
    url: "leaves/add",
    data: data
  });
}

export function deletePermission(data) {
  return request({
    method: "post",
    url: "leaves/delete",
    data: data
  });
}

export function getPermissionEmployee(data) {
  return request({
    method: "post",
    url: "employee/list_for_permission",
    data: data
  });
}
