<template>
  <v-card class="my-5" flat>
    <div class="d-flex align-center flex-wrap pa-2">
      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex flex-row">
        <v-select
          v-model="idSchoolYear"
          :items="listSchoolYear"
          :label="$t('journal.school_year')"
          :class="$vuetify.breakpoint.smAndUp && 'select-225'"
          :item-text="
            item =>
              `${item.start_year} / ${item.end_year}, semester ${item.semester}`
          "
          :loading="loadingSchoolYear"
          item-value="id"
          outlined
          hide-details
          dense
          class="body-2 ma-2"
        >
          <template #item="{ item }">
            <span class="caption">
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
        </v-select>
        <v-select
          v-model="idGrade"
          :items="listGrade"
          :label="$t('schedule.choose_grade')"
          :class="$vuetify.breakpoint.smAndUp && 'select-175'"
          :loading="loadingGrade"
          item-value="id"
          item-text="grade"
          outlined
          hide-details
          dense
          class="ma-2"
          @change="changeGrade"
        />
        <v-select
          v-model="idClass"
          :items="listClass"
          :label="$t('academic.schedule_data.type_of_class')"
          :disabled="loadingClass || !idGrade"
          :class="$vuetify.breakpoint.smAndUp && 'select-175'"
          :loading="loadingClass"
          item-value="id"
          item-text="name"
          outlined
          hide-details
          dense
          class="ma-2"
          @change="getPermission"
        />
      </div>
      <div v-else>
        <v-btn color="primary" class="mr-2" depressed @click="sheet = !sheet">
          <v-icon>mdi-filter-outline</v-icon>
        </v-btn>
      </div>
      <div class="font-weight-bold">
        {{ table.dataTable.total + " " + $t("permission.permission") }}
      </div>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        :disabled="!idClass ? true : false"
        clearable
        style="max-width: 250px"
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
      />
      <v-btn
        :disabled="loadingSchoolYear || idSchoolYearActive === 0 ? true : false"
        class="gradient-primary ma-2"
        dark
        depressed
        @click="addStudent = true"
      >
        {{ $t("permission.add_student") }}
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="arrayDelete.length !== 0"
        class="gradient-error ma-2"
        dark
        depressed
        @click="confirmDelete = true"
      >
        {{ $t("permission.delete_student") }}
        <v-icon class="ml-2">mdi-delete</v-icon>
      </v-btn>
    </div>
    <v-divider class="my-2" />
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pa-6">
        <v-row justify="center">
          <v-col cols="12" sm="4">
            <v-select
              v-model="idSchoolYear"
              :items="listSchoolYear"
              :label="$t('journal.school_year')"
              :item-text="
                item =>
                  `${item.start_year} / ${item.end_year}, semester ${item.semester}`
              "
              :loading="loadingSchoolYear"
              item-value="id"
              outlined
              hide-details
              dense
              class="mb-2"
            >
              <template #item="{ item }">
                <span class="caption">
                  {{ item.start_year }} / {{ item.end_year }}, semester
                  {{ item.semester }}
                </span>
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      :color="schoolYearInfo(item.status).color"
                      small
                      class="ml-1"
                      >{{ schoolYearInfo(item.status).name }}</v-icon
                    >
                  </template>
                  <span>{{ schoolYearInfo(item.status).info }}</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="idGrade"
              :items="listGrade"
              :label="$t('schedule.choose_grade')"
              :loading="loadingGrade"
              item-value="id"
              item-text="grade"
              outlined
              hide-details
              dense
              class="mb-2"
              @change="changeGrade"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="idClass"
              :items="listClass"
              :label="$t('academic.schedule_data.type_of_class')"
              :disabled="loadingClass || !idGrade"
              :loading="loadingClass"
              item-value="id"
              item-text="name"
              outlined
              hide-details
              dense
              class="mb-2"
              @change="getPermission"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
    <Table
      :table="table"
      :modelTable="arrayDelete"
      withAction
      @setSingleDelete="
        item => {
          singleDelete = item;
          confirmDelete = true;
        }
      "
      @setMultipleDelete="item => (arrayDelete = item)"
      @postDelete="saveDelete"
    />
    <Pagination
      :length="table.dataTable.last_page"
      :total="table.dataTable.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
      class="ma-3"
    />
    <DialogAddStudent
      :open="addStudent"
      :idSchoolYear="idSchoolYearActive"
      :listGrade="listGrade"
      @close="closeDialog"
    />
    <ModalConfirm
      :close="() => (this.confirmDelete = false)"
      :isOpen="confirmDelete"
      :save="saveDelete"
      :loading="loadingDelete"
    />
  </v-card>
</template>

<script>
import {
  get_class_list,
  get_school_year_list,
  get_grade_list
} from "@/api/admin/schoolClass";
import {
  getListPermission,
  deletePermission
} from "@/api/admin/absent/permission";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    Table: () => import("./components/Table"),
    Pagination: () => import("@/components/Pagination"),
    DialogAddStudent: () => import("./DialogAddStudent"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.getSchoolYear();
    this.getGrade();
  },
  computed: {
    search: {
      set(value) {
        this.table.search = value;
        this.searchHandler();
      },
      get() {
        return this.table.search;
      }
    }
  },
  data() {
    return {
      sheet: false,
      confirmDelete: false,
      loadingDelete: false,
      arrayDelete: [],
      singleDelete: {},
      addStudent: false,
      idSchoolYearActive: 0,
      idClass: 0,
      idSchoolYear: 0,
      listSchoolYear: [],
      loadingSchoolYear: false,
      loadingGrade: false,
      listGrade: [],
      idGrade: 0,
      listClass: [],
      loadingClass: false,
      loadingTable: false,
      table: {
        dataTable: { data: [], total: 0 },
        loading: false,
        search: "",
        page: 1,
        limit: 10
      },
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "HOMEROOM_TEACHER" : ""
    };
  },
  methods: {
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.table.page = 1;
        this.getPermission();
      }, doneTypingInterval);
    },
    async saveDelete() {
      this.loadingDelete = true;
      const body = this.arrayDelete.length
        ? this.arrayDelete
        : [this.singleDelete];
      try {
        const response = await deletePermission({ data: body });
        if (response.data.code) {
          this.getPermission();
          this.snackBar(
            false,
            this.$i18n.t("permission.msg.delete_permission")
          );
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.loadingDelete = false;
        console.error("saveDelete()", error);
      }
      this.loadingDelete = false;
      this.confirmDelete = false;
      this.arrayDelete = [];
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    closeDialog() {
      this.addStudent = false;
      if (this.idClass) this.getPermission();
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getPermission();
    },
    async getPermission() {
      this.table.loading = true;
      try {
        const response = await getListPermission({
          type: "student",
          class: this.idClass,
          search: this.table.search,
          page: this.table.page,
          limit: this.table.limit
        });
        if (response.data.code) {
          this.table.dataTable = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        console.log("getPermission()\n", error);
      }
      this.table.loading = false;
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    async getSchoolYear() {
      this.loadingSchoolYear = true;
      try {
        const res = await get_school_year_list();
        if (res.data.code) {
          const data = res.data.data;
          this.listSchoolYear = data;
          const schoolYearActive = data.find(item => item.status === 1);
          if (schoolYearActive) {
            this.idSchoolYear = schoolYearActive.id;
            if (!this.idSchoolYearActive) {
              this.idSchoolYearActive = schoolYearActive.school_year_master;
            }
          }
        } else {
          this.snackBar(true, res.data.message);
        }
      } catch (error) {
        console.error("getSchoolYear()\n", error);
      }
      this.loadingSchoolYear = false;
    },
    getGrade() {
      this.loadingGrade = true;
      get_grade_list({ type: this.typeList })
        .then(res => {
          if (res.data.code) {
            let r = res.data.data;
            this.listGrade = r;
            if (this.$route.query.grade) {
              this.idGrade = parseInt(this.$route.query.grade);
              this.changeGrade(this.idGrade);
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingGrade = false;
        })
        .catch(err => {
          this.loadingGrade = false;
          this.snackBar(true, err);
        });
    },
    changeGrade(grade) {
      this.loadingClass = true;
      get_class_list({
        grade: [grade],
        school_year: [this.idSchoolYear],
        type: this.typeList
      })
        .then(res => {
          if (res.data.code) {
            let r = res.data.data;
            this.listClass = r;
            if (this.$route.query.class) {
              this.modelClass = parseInt(this.$route.query.class);
            }
          } else {
            this.snackBar(true, res.data.message);
          }
          this.loadingClass = false;
        })
        .catch(err => {
          this.loadingClass = false;
          this.snackBar(true, err);
        });
    }
  }
};
</script>
